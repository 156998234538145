import React from "react";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";
import { dataportfolio, pageIds } from "../../content_option";
import LazyImage from "../../components/LazyImage";

export const Portfolio = () => {
  return (
    <Container
      id={pageIds.portfolio + "-container"}
      className="Portfolio-header"
    >
      <Row className="mb-5 pt-md-3">
        <Col lg="8">
          <h1 className="display-4 mb-4"> Portfolio </h1>{" "}
          <hr className="t_border my-4 ml-0 text-left" />
        </Col>
      </Row>
      <div className="mb-5 po_items_ho">
        {dataportfolio.map((data, i) => {
          return (
            <div key={i} className="po_item">
              <a href={data.link} target="_blank" rel="noopener noreferrer">
                <LazyImage src={data.img} alt="work" delay={0} />
              </a>
            </div>
          );
        })}
      </div>
    </Container>
  );
};
