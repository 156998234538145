import React, { useEffect, useState } from "react";
import "./style.css";
import { VscClose } from "react-icons/vsc";
import { AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { logotext, pageIds, socialprofils } from "../content_option";
import Themetoggle from "../components/themetoggle";

const Headermain = () => {
  const [isActive, setActive] = useState("false");
  let { hash } = useLocation();
  const [shadow, setShadow] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (hash) {
      const sectionId = hash.substr(1);
      scrollToDiv(sectionId);
    }
  }, [hash]);

  const scrollToDiv = (id) => {
    const itemRef = document.getElementById(id + "-container");
    if (itemRef) {
      window.scrollTo({
        top: itemRef.offsetTop - 50,
        behavior: "smooth",
      });
    }
  };

  const handleToggle = (id) => {
    setActive(!isActive);
    document.body.classList.toggle("ovhidden");
  };

  return (
    <>
      <header
        className={`fixed-top site__header ${shadow ? "header_shadow" : ""}`}
      >
        <div
          id="app-header"
          className="d-flex align-items-center justify-content-between app-header"
        >
          <div>
            <Link
              className="navbar-brand nav_ac"
              to="/"
              onClick={() => {
                window.scrollTo(0, 0);
                if (!isActive) handleToggle();
              }}
            >
              {logotext}
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <div className="menu_item_large_view">
              <Link to="/notes" className="my-3 menu_item_large_view_btn">
                Notes
              </Link>
              <Link
                to={`/#${pageIds.about}`}
                replace={true}
                className="my-3 menu_item_large_view_btn"
              >
                About
              </Link>
              <Link
                to={`/#${pageIds.portfolio}`}
                replace={true}
                className="my-3 menu_item_large_view_btn"
              >
                Portfolio
              </Link>
              <Link
                to={`/#${pageIds.contact}`}
                replace={true}
                className="my-3 menu_item_large_view_btn"
              >
                Contact
              </Link>
            </div>
            <Themetoggle />
            <button
              className="menu__button nav_ac"
              onClick={() => handleToggle()}
            >
              {!isActive ? (
                <VscClose className="close" />
              ) : (
                <AiOutlineMenu className="open" />
              )}
            </button>
          </div>
        </div>

        <div className={`site__navigation ${!isActive ? "menu__opend" : ""}`}>
          <div className="bg__menu h-100">
            <div className="menu__wrapper">
              <div className="menu__container p-3">
                <ul className="the_menu">
                  <li className="menu_item">
                    <Link
                      onClick={() => handleToggle()}
                      to="/notes"
                      className="my-3"
                    >
                      Notes
                    </Link>
                  </li>
                  <li className="menu_item">
                    <Link
                      onClick={() => handleToggle()}
                      to="/"
                      className="my-3"
                    >
                      Profile
                    </Link>
                  </li>
                  <li className="menu_item ">
                    <Link
                      onClick={() => handleToggle()}
                      to={`/#${pageIds.about}`}
                      replace={true}
                      className="my-3"
                    >
                      About
                    </Link>
                  </li>
                  <li className="menu_item ">
                    <Link
                      onClick={() => handleToggle()}
                      to={`/#${pageIds.portfolio}`}
                      replace={true}
                      className="my-3"
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li className="menu_item ">
                    <Link
                      onClick={() => handleToggle()}
                      to={`/#${pageIds.contact}`}
                      replace={true}
                      className="my-3"
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="menu_footer d-flex flex-column flex-md-row justify-content-between align-items-md-center position-absolute w-100 p-3">
            <div className="d-flex">
              {/* <a target="_blank" rel="noopener noreferrer" href={socialprofils.facebook}>Facebook</a> */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={socialprofils.github}
              >
                Github
              </a>
              {/* <a target="_blank" rel="noopener noreferrer" href={socialprofils.twitter}>Twitter</a> */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={socialprofils.linkedin}
              >
                LinkedIn
              </a>
            </div>
            <p className="copyright m-0">copyright __ {logotext}</p>
          </div>
        </div>
      </header>
    </>
  );
};

export default Headermain;
