import React, { useState } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import * as wholeData from "../../content_option";
import { FaRegCopy, FaCheck } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";

const { meta } = wholeData;

export const Note = () => {
  const { noteId } = useParams();
  const note = wholeData.notes.find((x) => x.route === noteId) || {};
  return (
    <HelmetProvider>
      <Container className="reacttips">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {" "}
            {note.title} | {meta.title}{" "}
          </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4"> <Link to={'/notes'}>{'Notes'}</Link> {'/'} {note.title} </h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        {/* <p className="info">click title to collapse/expand</p> */}
        <div className="pb-5">
          {note.data?.map((data, i) => (
            <Card key={data.title} data={data} i={i} />
          ))}
        </div>
      </Container>
    </HelmetProvider>
  );
};

function Card({ data, i }) {
  const [isOpen, setIsOpen] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  // const scrollToCard = () => {
  //   if(isOpen) return;
  //   const headerHeight = document.getElementById('app-header').offsetHeight;
  //   const element = document.getElementById(`card-${i}`);
  //   if (element) {
  //     const offset = element.offsetTop - headerHeight;
  //     window.scrollTo({ top: offset, behavior: 'smooth' });
  //   }
  // };

  const handleCopy = (txt) => {
    navigator.clipboard.writeText(txt);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 750); // Adjust the duration as needed
  };

  return (
    <div id={`card-${i}`} key={i} className="reacttips-item mb-5">
      <div
        className="reacttips-item-header"
        onClick={() => {
          setIsOpen((val) => val);
          // setIsOpen((val) => !val);
          // setTimeout(scrollToCard, 0);
        }}
      >
        <a href={data.titleLink} target="_blank" rel="noreferrer">
          <h2 className="title">
            {i + 1}. {data.title}
          </h2>
        </a>
        {isOpen && <p className="animate-container">{data.description}</p>}
      </div>
      {isOpen && data.code?.length > 0 && (
        <div className="animate-container">
          <pre className="code mt-3 p-2 p-sm-3 p-md-4">
            <code className="me-5">{data.code}</code>
          </pre>
          {showPopup ? <FaCheck className="copy" color="green" /> : <FaRegCopy className="copy" onClick={() => handleCopy(data.code)} />}
          {/* <div className={`copy-popup ${showPopup ? "show" : ""}`}>Copied</div> */}
        </div>
      )}
    </div>
  );
}
