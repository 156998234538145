import React from "react";
import { Home } from "../home";
import { About } from "../about";
import { Portfolio } from "../portfolio";
import { Container } from "react-bootstrap";
import { ContactUs } from "../contact";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { meta } from "../../content_option";

export default function AppContainer() {
  return (
    <HelmetProvider>
      <section id="home-container" className="home-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Container className="pb-5">
          <Home />
          <About />
          <Portfolio />
          <ContactUs />
        </Container>
      </section>
    </HelmetProvider>
  );
}
