import { useState, useEffect, useRef } from "react";

const useIntersectionObserver = (callback, options, delay = 100) => {
  const observer = useRef(null);
  const [element, setElement] = useState(null);
  const timeoutId = useRef(null);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        timeoutId.current = setTimeout(() => {
          callback();
        }, delay);
      } else {
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
        }
      }
    }, options);

    const { current: currentObserver } = observer;
    if (element) currentObserver.observe(element);

    return () => {
      currentObserver.disconnect();
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [element, options, callback, delay]);

  return setElement;
};

export default useIntersectionObserver;
