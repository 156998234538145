import React, { useEffect, useState } from "react";
import { WiMoonAltWaningCrescent4 } from "react-icons/wi";
import { MdOutlineWbSunny } from "react-icons/md";
import "./style.css";


const Themetoggle = () => {
  const [theme, settheme] = useState(localStorage.getItem("theme") || 'light');
  const themetoggle = () => {
    settheme(theme === "dark" ? "light" : "dark");
  };
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);
  const isDark = theme === "dark"
  return (
    <div className="nav_ac themetoggler" onClick={themetoggle} style={{ display: 'flex', alignItems: 'center' }}>
      <MdOutlineWbSunny className={`sun ${isDark ? 'show' : 'hide'}`} />
      <WiMoonAltWaningCrescent4 style={{ position: 'absolute' }} className={`moon ${isDark ? 'hide' : 'show'}`} />
    </div>
  );
};

export default Themetoggle;
