const leetcodeData = [
  {
    title: "Product of Array Except Self",
    titleLink: "https://leetcode.com/problems/product-of-array-except-self",
    description:
      `Given an integer array nums, return an array answer such that answer[i] is equal to the product of all the elements of nums except nums[i].

      The product of any prefix or suffix of nums is guaranteed to fit in a 32-bit integer.
      
      You must write an algorithm that runs in O(n) time and without using the division operation.`,
    code: `var productExceptSelf = function(arr) {
      const leftProduct = [];
      for (let i = 0; i < arr.length; i++) {
          if (i === 0) leftProduct[0] = 1;
          else leftProduct[i] = arr[i - 1] * leftProduct[i - 1];
      }
      const rightProduct = [];
      for (let i = arr.length - 1; i >= 0; i--) {
          if (i === arr.length - 1) rightProduct[arr.length - 1] = 1;
          else rightProduct[i] = arr[i + 1] * rightProduct[i + 1];
      }
      const final = [];
      for (let i = 0; i < arr.length; i++)
          final.push(leftProduct[i] * rightProduct[i]);
  
      return final;
  };`,
  },
  {
    title: "Container With Most Water",
    titleLink: "https://leetcode.com/problems/container-with-most-water",
    description: `You are given an integer array height of length n. There are n vertical lines drawn such that the two endpoints of the ith line are (i, 0) and (i, height[i]).

    Find two lines that together with the x-axis form a container, such that the container contains the most water.
    
    Return the maximum amount of water a container can store.
    
    Notice that you may not slant the container.`,
    code: `var maxArea = function(height) {
      let left = 0;
      let right = height.length - 1;
      let maxArea = 0;
      while (left < right) {
          let area = (right - left) * Math.min(height[left], height[right])
          maxArea = Math.max(maxArea, area);
          if(height[left] > height[right]) right--;
          else left ++;
      }
      return maxArea;
  };`,
  },
  {
    title: "Max Consecutive Ones III",
    titleLink: "https://leetcode.com/problems/max-consecutive-ones-iii",
    description: `Given a binary array nums and an integer k, return the maximum number of consecutive 1's in the array if you can flip at most k 0's.`,
    code: `var longestOnes = function (nums, k) {
      let max = -Infinity;
      let left = 0;
      let zeros = 0;
      for (let right = 0; right < nums.length; right++) {
          if (nums[right] === 0) zeros++;
          while (zeros > k) {
              if (nums[left] === 0) zeros--;
              left++;
          }
          max = Math.max(max, right - left + 1);
      }
      return Math.max(max, 0);
  };`,
  },
  {
    title: "Find the Highest Altitude",
    titleLink: "https://leetcode.com/problems/find-the-highest-altitude",
    description: `There is a biker going on a road trip. The road trip consists of n + 1 points at different altitudes. The biker starts his trip on point 0 with altitude equal 0.

    You are given an integer array gain of length n where gain[i] is the net gain in altitude between points i​​​​​​ and i + 1 for all (0 <= i < n). Return the highest altitude of a point.`,
    code: `var largestAltitude = function (gain) {
      let sum = 0;
      let maxHeight = 0;
      for (let val of gain) {
          sum += val;
          maxHeight = Math.max(maxHeight, sum);
      }
      return maxHeight;
  };`,
  },
  {
    title: "Reverse Linked List",
    titleLink: "https://leetcode.com/problems/reverse-linked-list",
    description: `Given the head of a singly linked list, reverse the list, and return the reversed list.`,
    code: `var reverseList = function(head) { 
      const traverse = (node, prev) => {
          if(!node) return prev;
          let temp = node.next
          node.next = prev;
          return traverse(temp, node)
      };
      return traverse(head, null)
  };`,
  },
  {
    title: "Maximum Depth of Binary Tree",
    titleLink: "https://leetcode.com/problems/maximum-depth-of-binary-tree",
    description: `Given the root of a binary tree, return its maximum depth.

    A binary tree's maximum depth is the number of nodes along the longest path from the root node down to the farthest leaf node.`,
    code: `var maxDepth = function(root) {
      if(!root) return 0;
      let q = [root];
      let height = 0;
      while(q.length){
          let newQ = [];
          q.forEach(node => {
              if(node.left) newQ.push(node.left);
              if(node.right) newQ.push(node.right);
          })
          q = newQ
          height++;
      }
      return height;
  };`,
  },
  {
    title: "Binary Tree Right Side View",
    titleLink: "https://leetcode.com/problems/binary-tree-right-side-view",
    description: `Given the root of a binary tree, imagine yourself standing on the right side of it, return the values of the nodes you can see ordered from top to bottom.`,
    code: `var rightSideView = function (root) {
      if (!root) return [];
      let q = [root];
      const res = [root.val];
      while (q.length) {
          let newQ = [];
          q.forEach(node => {
              if (node.left) newQ.push(node.left);
              if (node.right) newQ.push(node.right);
          })
          q = newQ
          if (newQ.length) res.push(newQ[newQ.length - 1].val)
      };
      return res;
  };`,
  },
  {
    title: "Combination Sum III",
    titleLink: "https://leetcode.com/problems/combination-sum-iii",
    description: `Find all valid combinations of k numbers that sum up to n such that the following conditions are true:

    Only numbers 1 through 9 are used.
    Each number is used at most once.
    Return a list of all possible valid combinations. The list must not contain the same combination twice, and the combinations may be returned in any order.`,
    code: `var combinationSum3 = function (k, n) {
      const res = [];
      const backtrack = (i, sum, arr) => {
          if (arr.length === k) {
              if (sum === n) res.push(arr);
              return;
          }
          if (sum > n || i === 10) return;
          backtrack(i + 1, sum, arr);
          backtrack(i + 1, sum + i, [...arr, i]);
      }
      backtrack(1, 0, []);
      return res;
  };`,
  },
  {
    title: "Min Cost Climbing Stairs",
    titleLink: "https://leetcode.com/problems/min-cost-climbing-stairs",
    description: `You are given an integer array cost where cost[i] is the cost of ith step on a staircase. Once you pay the cost, you can either climb one or two steps.

    You can either start from the step with index 0, or the step with index 1.
    
    Return the minimum cost to reach the top of the floor.`,
    code: `var minCostClimbingStairs = function (cost) {
      const n = cost.length;
      for (let i = n - 3; i >= 0; i--)
          cost[i] = cost[i] + Math.min(cost[i + 1], cost[i + 2]);
      return Math.min(cost[0], cost[1]);
  };`,
  },
  {
    title: "Daily Temperatures",
    titleLink: "https://leetcode.com/problems/daily-temperatures",
    description: `Given an array of integers temperatures represents the daily temperatures, return an array answer such that answer[i] is the number of days you have to wait after the ith day to get a warmer temperature. If there is no future day for which this is possible, keep answer[i] == 0 instead.`,
    code: `var dailyTemperatures = function (temps) {
      const result = new Array(temps.length).fill(0);
      const stack = [];
      for (let i = temps.length - 1; i >= 0; i--) {
          whileLoop: while (stack.length) {
              if (temps[stack[stack.length - 1]] > temps[i]) {
                  result[i] = stack[stack.length - 1] - i;
                  break whileLoop;
              } else stack.pop();
          }
          stack.push(i);
      }
      return result;
  };`,
  },
  {
    title: "Longest Substring Without Repeating Characters",
    titleLink: "https://leetcode.com/problems/longest-substring-without-repeating-characters",
    description: `Given a string s, find the length of the longest substring without repeating characters.`,
    code: `var lengthOfLongestSubstring = function (s) {
      let n = s.length;
      if (n === 0) return 0;
      let left = 0;
      let maxLength = -Infinity;
      const hash = {};
      for (let right = 0; right < n; right++) {
          let char = s.charAt(right);
          if (hash[char] !== undefined && hash[char] >= left) {
              maxLength = Math.max(maxLength, right - left);
              left = hash[char] + 1;
          }
          hash[char] = right;
      }
      maxLength = Math.max(maxLength, n - left);
      return maxLength;
  };`,
  },
  {
    title: "Search in Rotated Sorted Array",
    titleLink: "https://leetcode.com/problems/search-in-rotated-sorted-array",
    description: `There is an integer array nums sorted in ascending order (with distinct values).

    Prior to being passed to your function, nums is possibly rotated at an unknown pivot index k (1 <= k < nums.length) such that the resulting array is [nums[k], nums[k+1], ..., nums[n-1], nums[0], nums[1], ..., nums[k-1]] (0-indexed). For example, [0,1,2,4,5,6,7] might be rotated at pivot index 3 and become [4,5,6,7,0,1,2].
    
    Given the array nums after the possible rotation and an integer target, return the index of target if it is in nums, or -1 if it is not in nums.
    
    You must write an algorithm with O(log n) runtime complexity.`,
    code: `var search = function (nums, target) {
      let n = nums.length;
      if (n === 1) return nums[0] === target ? 0 : -1;
      let low = 0;
      let high = n - 1;
      while (low <= high) {
          let mid = Math.floor((low + high) / 2);
          if (nums[mid] === target) return mid;
          if (nums[low] <= nums[mid]) {
              if (nums[low] <= target && target < nums[mid]) {
                  high = mid - 1;
              } else {
                  low = mid + 1;
              }
          } else {
              if (nums[mid] < target && target <= nums[high]) {
                  low = mid + 1;
              } else {
                  high = mid - 1;
              }
          }
      }
      return -1;
  };`,
  },
  {
    title: "First Missing Positive",
    titleLink: "https://leetcode.com/problems/first-missing-positive",
    description: `Given an unsorted integer array nums, return the smallest missing positive integer.

    You must implement an algorithm that runs in O(n) time and uses O(1) auxiliary space.`,
    code: `var firstMissingPositive = function (nums) {
      const map = {};
      let max = -Infinity;
      for (let i = 0; i < nums.length; i++) {
          map[nums[i]] = true;
          max = Math.max(max, nums[i]);
      }
      for (let i = 1; i <= max; i++) {
          if(!map[i]) return i;
      }
      return Math.max(1, max + 1);
  };`,
  },
  {
    title: "Permutations",
    titleLink: "https://leetcode.com/problems/permutations",
    description: `Given an array nums of distinct integers, return all the possible permutations. You can return the answer in any order.`,
    code: `var permute = function(nums) {
      const res = [];
      const backtrack = (permutations) => {
          if(permutations.length === nums.length){
              res.push(permutations);
              return;
          };
          for(let num of nums){
              if(!permutations.includes(num)){
                  backtrack([...permutations, num]);
              }
          }
      };
      backtrack([]);
      return res;
  };`,
  },
  // {
  //   title: "aa",
  //   titleLink: "aa",
  //   description: `aa`,
  //   code: `aa`,
  // },
  // {
  //   title: "aa",
  //   titleLink: "aa",
  //   description: `aa`,
  //   code: `aa`,
  // },
  // {
  //   title: "aa",
  //   titleLink: "aa",
  //   description: `aa`,
  //   code: `aa`,
  // },
];

export { leetcodeData };
