import React, { useEffect, useState } from 'react';
import './style.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {  Container } from 'react-bootstrap';
import { meta } from '../../content_option';
import Themetoggle from '../../components/themetoggle';
import { useLocation } from 'react-router-dom';


const Products = () => {
  const location = useLocation();
  const standalone = location.pathname === "/prods"
  const [srcs, setSrcs] = useState([
    {
      "url": "data:text/html;charset=utf-8,<html><body><p>Loading...</p></body></html>",
      "id": -1
    },
    {
      "url": "data:text/html;charset=utf-8,<html><body><p>Loading...</p></body></html>",
      "id": -2
    },
    {
      "url": "data:text/html;charset=utf-8,<html><body><p>Loading...</p></body></html>",
      "id": -3
    },
    {
      "url": "data:text/html;charset=utf-8,<html><body><p>Loading...</p></body></html>",
      "id": -4
    },
  ]);
  useEffect(() => {
    const jsonFileUrl = 'https://raw.githubusercontent.com/aswincheriyala/data-store/main/products.json';
    fetch(jsonFileUrl)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setSrcs(data.products)
      })
      .catch(error => {
        console.error('Error fetching JSON file:', error);
      });
  }, []);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <HelmetProvider>
      <Container className="products">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Products</title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        {standalone && <div className='theme-toggle'>
          <Themetoggle />
        </div>}
        {/* <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4"> Products </h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row> */}
        <div className='d-flex flex-wrap justify-content-center justify-content-md-start mt-5'>
          {srcs.map(src => (
            <div className='me-4 ms-4 mb-5' style={{position: 'relative'}}>
              <iframe
                title={src.url}
                key={src.id}
                className='card'
                sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                style={{ height: 240, width: 120 }} src={src.url}
              />
              <div className='card-overlay' onClick={()=> {if(src.link) openInNewTab(src.link)}} />
            </div>
          ))}
        </div>
      </Container>
    </HelmetProvider>
  );
};

export default Products;
