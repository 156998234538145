const jsAlgorithms = [
  {
    title: "Binary Search",
    description: "Binary search is an efficient search algorithm that finds the position of a target value within a sorted array. It compares the target value to the middle element of the array and eliminates half of the remaining elements at each step.",
    code: `function binarySearch(arr, target) {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (arr[mid] === target) {
      return mid;
    } else if (arr[mid] < target) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return -1;
}

const sortedArray = [1, 3, 5, 7, 9, 11, 13];
console.log(binarySearch(sortedArray, 7)); // Output: 3
    `,
  },
  {
    title: "Bubble Sort",
    description: "Bubble sort is a simple sorting algorithm that repeatedly steps through the list to be sorted, compares adjacent elements, and swaps them if they are in the wrong order.",
    code: `function bubbleSort(arr) {
  const n = arr.length;
  for (let i = 0; i < n - 1; i++) {
    for (let j = 0; j < n - i - 1; j++) {
      if (arr[j] > arr[j + 1]) {
        // Swap arr[j] and arr[j + 1]
        const temp = arr[j];
        arr[j] = arr[j + 1];
        arr[j + 1] = temp;
      }
    }
  }
  return arr;
}

const unsortedArray = [64, 34, 25, 12, 22, 11, 90];
console.log(bubbleSort(unsortedArray)); // Output: [11, 12, 22, 25, 34, 64, 90]
`,
  },
  {
    title: "Factorial Calculation",
    description: "Factorial is the product of an integer and all the positive integers below it. It is commonly used in permutation and combination calculations.",
    code: `function factorial(n) {
  if (n === 0 || n === 1) {
    return 1;
  } else {
    return n * factorial(n - 1);
  }
}

console.log(factorial(5)); // Output: 120
`,
  },
  {
    title: "Merge Sort",
    description: "Merge sort is a divide and conquer algorithm that divides the input array into smaller sub-arrays, sorts them, and then merges them back together.",
    code: `function mergeSort(arr) {
  if (arr.length <= 1) {
    return arr;
  }

  const mid = Math.floor(arr.length / 2);
  const left = mergeSort(arr.slice(0, mid));
  const right = mergeSort(arr.slice(mid));

  return merge(left, right);
}

function merge(left, right) {
  let result = [];
  let leftIndex = 0;
  let rightIndex = 0;

  while (leftIndex < left.length && rightIndex < right.length) {
    if (left[leftIndex] < right[rightIndex]) {
      result.push(left[leftIndex]);
      leftIndex++;
    } else {
      result.push(right[rightIndex]);
      rightIndex++;
    }
  }

  return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
}

const unsortedArray = [38, 27, 43, 3, 9, 82, 10];
console.log(mergeSort(unsortedArray)); // Output: [3, 9, 10, 27, 38, 43, 82]
`,
  },
  {
    title: "Fibonacci Sequence",
    description: "The Fibonacci sequence is a series of numbers in which each number is the sum of the two preceding ones, usually starting with 0 and 1.",
    code: `function fibonacci(n) {
  if (n <= 1) {
    return n;
  } else {
    return fibonacci(n - 1) + fibonacci(n - 2);
  }
}

console.log(fibonacci(6)); // Output: 8
`,
  },
  {
    title: "Quick Sort",
    description: "Quick sort is a divide and conquer algorithm that selects a 'pivot' element from the array and partitions the other elements into two sub-arrays according to the pivot. It then recursively sorts the sub-arrays.",
    code: `function quickSort(arr) {
  if (arr.length <= 1) {
    return arr;
  }

  const pivot = arr[0];
  const left = [];
  const right = [];

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] < pivot) {
      left.push(arr[i]);
    } else {
      right.push(arr[i]);
    }
  }

  return [...quickSort(left), pivot, ...quickSort(right)];
}

const unsortedArray = [38, 27, 43, 3, 9, 82, 10];
console.log(quickSort(unsortedArray)); // Output: [3, 9, 10, 27, 38, 43, 82]
`,
  },
  {
    title: "Dijkstra's Algorithm",
    description: "Dijkstra's algorithm finds the shortest path from a starting node to all other nodes in a weighted graph. It is commonly used in network routing protocols and map applications.",
    code: `function dijkstrasAlgorithm(graph, start) {
  const distances = {};
  const visited = {};
  const queue = new PriorityQueue();

  for (let vertex in graph) {
    distances[vertex] = Infinity;
    visited[vertex] = false;
  }

  distances[start] = 0;
  queue.enqueue(start, 0);

  while (!queue.isEmpty()) {
    const { element: current } = queue.dequeue();
    if (visited[current]) continue;
    visited[current] = true;

    for (let neighbor in graph[current]) {
      const distance = distances[current] + graph[current][neighbor];
      if (distance < distances[neighbor]) {
        distances[neighbor] = distance;
        queue.enqueue(neighbor, distance);
      }
    }
  }

  return distances;
}

const graph = {
  A: { B: 1, C: 4 },
  B: { A: 1, C: 2, D: 5 },
  C: { A: 4, B: 2, D: 1 },
  D: { B: 5, C: 1 },
};

console.log(dijkstrasAlgorithm(graph, "A")); // Output: { A: 0, B: 1, C: 3, D: 4 }
`,
  },
  {
    title: "Knuth–Morris–Pratt Algorithm",
    description: "The Knuth–Morris–Pratt algorithm is used for string searching. It efficiently finds all occurrences of a pattern in a given text.",
    code: `function kmpSearch(text, pattern) {
  const lps = computeLPSArray(pattern);
  const occurrences = [];

  let i = 0; // Index for text[]
  let j = 0; // Index for pattern[]

  while (i < text.length) {
    if (pattern[j] === text[i]) {
      i++;
      j++;
    }

    if (j === pattern.length) {
      occurrences.push(i - j);
      j = lps[j - 1];
    } else if (i < text.length && pattern[j] !== text[i]) {
      if (j !== 0) {
        j = lps[j - 1];
      } else {
        i++;
      }
    }
  }

  return occurrences;
}

function computeLPSArray(pattern) {
  const lps = [0];
  let len = 0; // Length of the previous longest prefix suffix

  let i = 1;
  while (i < pattern.length) {
    if (pattern[i] === pattern[len]) {
      len++;
      lps[i] = len;
      i++;
    } else {
      if (len !== 0) {
        len = lps[len - 1];
      } else {
        lps[i] = 0;
        i++;
      }
    }
  }

  return lps;
}

const text = "ABABDABACDABABCABAB";
const pattern = "ABABCABAB";
console.log(kmpSearch(text, pattern)); // Output: [10]
`,
  },
  {
    title: "Sieve of Eratosthenes",
    description: "The Sieve of Eratosthenes is an efficient algorithm for finding all prime numbers up to a given limit. It eliminates the multiples of each prime number to identify the primes.",
    code: `function sieveOfEratosthenes(limit) {
  const primes = [];
  const sieve = new Array(limit + 1).fill(true);

  for (let p = 2; p * p <= limit; p++) {
    if (sieve[p] === true) {
      for (let i = p * p; i <= limit; i += p) {
        sieve[i] = false;
      }
    }
  }

  for (let p = 2; p <= limit; p++) {
    if (sieve[p] === true) {
      primes.push(p);
    }
  }

  return primes;
}

console.log(sieveOfEratosthenes(30)); // Output: [2, 3, 5, 7, 11, 13, 17, 19, 23, 29]
`,
  },
  {
    title: "Euclidean Algorithm (GCD)",
    description: "The Euclidean algorithm finds the greatest common divisor (GCD) of two numbers. It is commonly used in many mathematical computations.",
    code: `function euclideanAlgorithm(a, b) {
  return b === 0 ? a : euclideanAlgorithm(b, a % b);
}

console.log(euclideanAlgorithm(48, 18)); // Output: 6
`,
  },
  {
    title: "Depth-First Search (DFS)",
    description: "Depth-First Search is an algorithm used to traverse a graph or tree. It starts from a source node and explores as far as possible along each branch before backtracking.",
    code: `class Graph {
  constructor() {
    this.adjList = new Map();
  }

  addVertex(vertex) {
    if (!this.adjList.has(vertex)) {
      this.adjList.set(vertex, []);
    }
  }

  addEdge(vertex1, vertex2) {
    this.adjList.get(vertex1).push(vertex2);
    this.adjList.get(vertex2).push(vertex1);
  }

  dfs(startingNode) {
    const visited = new Set();
    this.dfsHelper(startingNode, visited);
  }

  dfsHelper(node, visited) {
    visited.add(node);
    console.log(node);

    const neighbors = this.adjList.get(node);
    for (const neighbor of neighbors) {
      if (!visited.has(neighbor)) {
        this.dfsHelper(neighbor, visited);
      }
    }
  }
}

const graph = new Graph();
const vertices = ["A", "B", "C", "D", "E", "F"];
vertices.forEach((vertex) => graph.addVertex(vertex));

graph.addEdge("A", "B");
graph.addEdge("A", "C");
graph.addEdge("B", "D");
graph.addEdge("B", "E");
graph.addEdge("C", "F");

graph.dfs("A"); // Output: A B D E C F
`,
  },
  {
    title: "Breadth-First Search (BFS)",
    description: "Breadth-First Search is another algorithm used to traverse a graph or tree. It explores all the neighbor nodes at the present depth before moving to the next level.",
    code: `class Graph {
  constructor() {
    this.adjList = new Map();
  }

  addVertex(vertex) {
    if (!this.adjList.has(vertex)) {
      this.adjList.set(vertex, []);
    }
  }

  addEdge(vertex1, vertex2) {
    this.adjList.get(vertex1).push(vertex2);
    this.adjList.get(vertex2).push(vertex1);
  }

  bfs(startingNode) {
    const visited = new Set();
    const queue = [];

    visited.add(startingNode);
    queue.push(startingNode);

    while (queue.length) {
      const currentNode = queue.shift();
      console.log(currentNode);

      const neighbors = this.adjList.get(currentNode);
      for (const neighbor of neighbors) {
        if (!visited.has(neighbor)) {
          visited.add(neighbor);
          queue.push(neighbor);
        }
      }
    }
  }
}

const graph = new Graph();
const vertices = ["A", "B", "C", "D", "E", "F"];
vertices.forEach((vertex) => graph.addVertex(vertex));

graph.addEdge("A", "B");
graph.addEdge("A", "C");
graph.addEdge("B", "D");
graph.addEdge("B", "E");
graph.addEdge("C", "F");

graph.bfs("A"); // Output: A B C D E F
`,
  },
  {
    title: "Kadane's Algorithm",
    description: "Kadane's algorithm is used to find the maximum sum subarray in an array of integers. It efficiently handles both positive and negative numbers.",
    code: `function kadanesAlgorithm(arr) {
  let maxSoFar = arr[0];
  let maxEndingHere = arr[0];

  for (let i = 1; i < arr.length; i++) {
    maxEndingHere = Math.max(arr[i], maxEndingHere + arr[i]);
    maxSoFar = Math.max(maxSoFar, maxEndingHere);
  }

  return maxSoFar;
}

const inputArray = [-2, 1, -3, 4, -1, 2, 1, -5, 4];
console.log(kadanesAlgorithm(inputArray)); // Output: 6 (The subarray [4, -1, 2, 1] has the maximum sum of 6.)
`,
  },
  {
    title: "Flood Fill Algorithm",
    description: "The flood fill algorithm is used to 'paint' connected regions in a grid. It is commonly used in image processing and game development.",
    code: `function floodFill(image, sr, sc, newColor) {
  const originalColor = image[sr][sc];

  if (originalColor === newColor) {
    return image;
  }

  const dfs = (r, c) => {
    if (
      r < 0 ||
      r >= image.length ||
      c < 0 ||
      c >= image[0].length ||
      image[r][c] !== originalColor
    ) {
      return;
    }

    image[r][c] = newColor;

    dfs(r - 1, c);
    dfs(r + 1, c);
    dfs(r, c - 1);
    dfs(r, c + 1);
  };

  dfs(sr, sc);
  return image;
}

const image = [
  [1, 1, 1],
  [1, 1, 0],
  [1, 0, 1],
];
const sr = 1;
const sc = 1;
const newColor = 2;

console.log(floodFill(image, sr, sc, newColor));
// Output:
// [
//   [2, 2, 2],
//   [2, 2, 0],
//   [2, 0, 1]
// ]
`,
  },
  {
    title: "Topological Sort",
    description: "Topological sort is an ordering of vertices in a directed acyclic graph (DAG), where each vertex comes before all the vertices to which it has outgoing edges.",
    code: `class Graph {
  constructor() {
    this.adjList = new Map();
  }

  addVertex(vertex) {
    if (!this.adjList.has(vertex)) {
      this.adjList.set(vertex, []);
    }
  }

  addEdge(vertex1, vertex2) {
    this.adjList.get(vertex1).push(vertex2);
  }

  topologicalSort() {
    const visited = new Set();
    const stack = [];

    const dfs = (node) => {
      visited.add(node);

      const neighbors = this.adjList.get(node);
      for (const neighbor of neighbors) {
        if (!visited.has(neighbor)) {
          dfs(neighbor);
        }
      }

      stack.push(node);
    };

    for (const vertex of this.adjList.keys()) {
      if (!visited.has(vertex)) {
        dfs(vertex);
      }
    }

    return stack.reverse();
  }
}

const graph = new Graph();
const vertices = ["A", "B", "C", "D", "E"];
vertices.forEach((vertex) => graph.addVertex(vertex));

graph.addEdge("A", "C");
graph.addEdge("C", "D");
graph.addEdge("B", "D");
graph.addEdge("B", "E");
graph.addEdge("D", "E");

console.log(graph.topologicalSort()); // Output: [ 'B', 'A', 'C', 'D', 'E' ]
`,
  },
  {
    title: "Longest Common Subsequence (LCS)",
    description: "The longest common subsequence is the longest sequence of characters that appear in the same order in both strings. It is a fundamental problem in string processing.",
    code: `function longestCommonSubsequence(text1, text2) {
  const m = text1.length;
  const n = text2.length;

  const dp = new Array(m + 1).fill(0).map(() => new Array(n + 1).fill(0));

  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      if (text1[i - 1] === text2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
      } else {
        dp[i][j] = Math.max(dp[i - 1][j], dp[i][j - 1]);
      }
    }
  }

  // Backtrack to find the longest common subsequence
  let i = m;
  let j = n;
  let lcs = "";

  while (i > 0 && j > 0) {
    if (text1[i - 1] === text2[j - 1]) {
      lcs = text1[i - 1] + lcs;
      i--;
      j--;
    } else {
      if (dp[i - 1][j] > dp[i][j - 1]) {
        i--;
      } else {
        j--;
      }
    }
  }

  return lcs;
}

const text1 = "ABCDGH";
const text2 = "AEDFHR";
console.log(longestCommonSubsequence(text1, text2)); // Output: "ADH"
`,
  },
];

export {jsAlgorithms};
