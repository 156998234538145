const htmlTagTips = [
  {
    title: 'Using the <header> Element',
    description: 'Use the <header> element to define a header for a document or a section within the document.',
    code: '<header><h1>Welcome to My Website</h1></header>'
  },
  {
    title: 'Semantic <main> Element',
    description: 'Wrap the main content of your webpage using the <main> element to indicate the primary content of the page.',
    code: '<main><p>This is the main content of the page.</p></main>'
  },
  {
    title: 'Creating a Navigation Bar with <nav>',
    description: 'Use the <nav> element to create a navigation bar or menu for your website.',
    code: '<nav><ul><li><a href="/">Home</a></li><li><a href="/about">About</a></li></ul></nav>'
  },
  {
    title: 'Structuring Sections with <section>',
    description: 'Use the <section> element to define sections within your content, such as chapters, headers, or footers.',
    code: '<section><h2>Section Title</h2><p>Section content goes here.</p></section>'
  },
  {
    title: 'Grouping Elements with <div>',
    description: 'The <div> element is a versatile container that groups elements for styling or JavaScript manipulation.',
    code: '<div><p>Content inside a div element.</p></div>'
  },
  {
    title: 'Displaying Lists with <ul> and <ol>',
    description: 'Use <ul> for unordered lists and <ol> for ordered lists. <li> elements represent list items.',
    code: '<ul><li>Item 1</li><li>Item 2</li></ul><ol><li>First Item</li><li>Second Item</li></ol>'
  },
  {
    title: 'Creating Hyperlinks with <a>',
    description: 'Use <a> to create hyperlinks to other web pages or resources.',
    code: '<a href="https://example.com">Visit Example Website</a>'
  },
  {
    title: 'Using <img> for Images',
    description: 'The <img> element displays images on your webpage.',
    code: '<img src="image.jpg" alt="Image description">'
  },
  {
    title: 'Including Videos with <video>',
    description: 'Embed videos on your webpage using the <video> element.',
    code: '<video controls><source src="video.mp4" type="video/mp4">Your browser does not support the video tag.</video>'
  },
  {
    title: 'Adding Audio with <audio>',
    description: 'Use the <audio> element to add audio content to your webpage.',
    code: '<audio controls><source src="audio.mp3" type="audio/mpeg">Your browser does not support the audio tag.</audio>'
  },
  {
    title: 'Embedding YouTube Videos',
    description: 'Embed YouTube videos on your webpage using an iframe.',
    code: '<iframe width="560" height="315" src="https://www.youtube.com/embed/your-video-id" frameborder="0" allowfullscreen></iframe>'
  },
  {
    title: 'Creating Tables with <table>',
    description: 'Use the <table> element to create structured tables on your webpage.',
    code: '<table><tr><th>Name</th><th>Age</th></tr><tr><td>John</td><td>30</td></tr></table>'
  },
  {
    title: 'Defining Table Headers with <thead>',
    description: 'Use <thead> to group table header content inside a <table> element.',
    code: '<table><thead><tr><th>Name</th><th>Age</th></tr></thead><tbody><tr><td>John</td><td>30</td></tr></tbody></table>'
  },
  {
    title: 'Creating Form Inputs',
    description: 'Use various form elements like <input>, <textarea>, <select>, and <button> to create interactive forms.',
    code: '<form><input type="text" name="name" placeholder="Name"><textarea name="message" placeholder="Your message"></textarea><button type="submit">Submit</button></form>'
  },
  {
    title: 'Grouping Form Elements with <fieldset>',
    description: 'Use the <fieldset> element to group related form elements and add a legend for clarification.',
    code: '<form><fieldset><legend>Contact Information</legend><input type="text" name="name"><input type="email" name="email"></fieldset></form>'
  },
  {
    title: 'Using <label> for Form Accessibility',
    description: 'Use the <label> element to associate form elements with their labels for better accessibility.',
    code: '<form><label for="name">Name:</label><input type="text" id="name" name="name"></form>'
  },
  {
    title: 'Adding Line Breaks with <br>',
    description: 'The <br> element inserts a line break within text content.',
    code: '<p>This is the first line.<br>This is the second line.</p>'
  },
  {
    title: 'Displaying Blockquotes with <blockquote>',
    description: 'Use the <blockquote> element to display quoted content in block form.',
    code: '<blockquote><p>This is a quoted text.</p></blockquote>'
  },
  {
    title: 'Inline Quotes with <q>',
    description: 'Use the <q> element for inline quotations.',
    code: '<p><q>This is an inline quotation.</q></p>'
  },
  {
    title: 'Defining Abbreviations with <abbr>',
    description: 'Use <abbr> to define abbreviations and acronyms, optionally providing the full text as a title attribute.',
    code: '<p><abbr title="World Wide Web">WWW</abbr> is an abbreviation for World Wide Web.</p>'
  },
  {
    title: 'Highlighting Text with <mark>',
    description: 'The <mark> element highlights text within its context.',
    code: '<p>This is <mark>highlighted</mark> text.</p>'
  },
  {
    title: 'Adding Superscripts and Subscripts',
    description: 'Use <sup> for superscripts and <sub> for subscripts.',
    code: '<p>H<sub>2</sub>O is a chemical formula. 10<sup>2</sup> is 100.</p>'
  },
  {
    title: 'Using <time> for Dates and Times',
    description: 'Use <time> to define a specific date, time, or duration within your content.',
    code: '<p>My birthday is on <time datetime="2023-07-15">July 15th, 2023</time>.</p>'
  },
  {
    title: 'Creating Unordered Description Lists',
    description: 'Use the <dl>, <dt>, and <dd> elements to create unordered description lists.',
    code: '<dl><dt>Term 1</dt><dd>Description 1</dd><dt>Term 2</dt><dd>Description 2</dd></dl>'
  },
  {
    title: 'Displaying Code with <code>',
    description: 'Use the <code> element to display inline code snippets.',
    code: '<p>Use the <code>print()</code> function to display output.</p>'
  },
  {
    title: 'Using <kbd> for Keyboard Input',
    description: 'Use <kbd> to represent user keyboard input.',
    code: '<p>Press <kbd>Ctrl</kbd> + <kbd>C</kbd> to copy.</p>'
  },
  {
    title: 'Inserting <var> for Variables',
    description: 'Use <var> to indicate variables or placeholders.',
    code: '<p>The area of a circle is calculated using the formula <var>πr<sup>2</sup></var>.</p>'
  },
  {
    title: 'Adding Horizontal Rules with <hr>',
    description: 'The <hr> element inserts a horizontal rule to separate content.',
    code: '<p>Some content above<hr>Some content below</p>'
  },
  {
    title: 'Using <address> for Contact Information',
    description: 'Use <address> to indicate contact information for the nearest ancestor or article.',
    code: '<address><p>Contact us at <a href="mailto:contact@example.com">contact@example.com</a></p></address>'
  },
  {
    title: 'Wrapping Content in <article>',
    description: 'The <article> element wraps standalone content that can be distributed independently, such as a blog post or news article.',
    code: '<article><h2>Article Title</h2><p>Article content goes here.</p></article>'
  },
  {
    title: 'Adding Figure and Figcaption',
    description: 'Use <figure> and <figcaption> to associate a caption with an image or a multimedia object.',
    code: '<figure><img src="image.jpg" alt="Image description"><figcaption>Image caption goes here.</figcaption></figure>'
  },
  {
    title: 'Creating <details> and <summary> for Expandable Content',
    description: 'Use <details> and <summary> to create expandable sections of content.',
    code: '<details><summary>Click to reveal more information</summary><p>Additional content goes here.</p></details>'
  },
  {
    title: 'Using <meter> for Progress Indicators',
    description: 'Use <meter> to display a numeric value within a predefined range.',
    code: '<p>Progress: <meter value="75" min="0" max="100">75%</meter></p>'
  },
  {
    title: 'Wrapping Content in <aside>',
    description: 'The <aside> element wraps content that is tangentially related to the main content, such as sidebars or callouts.',
    code: '<aside><p>This is a sidebar content.</p></aside>'
  },
  {
    title: 'Using <bdo> for Bi-Directional Text',
    description: 'Use <bdo> to override the text directionality for special cases.',
    code: '<p><bdo dir="rtl">This text will be right-to-left</bdo></p>'
  },
  {
    title: 'Creating Superscript Footnotes with <sup>',
    description: 'Use <sup> to create superscript footnotes or references.',
    code: '<p>More information can be found in the <a href="#footnote1">superscript footnote<sup>1</sup></a>.</p>'
  },
  {
    title: 'Creating Data Tables with <data>',
    description: 'Use <data> to semantically represent a machine-readable value within a table.',
    code: '<table><tr><th>Product</th><th>Price</th></tr><tr><td><data value="123456">Product A</data></td><td><data value="49.99">$49.99</data></td></tr></table>'
  },
  {
    title: 'Using <details> and <summary> for Expandable Content',
    description: 'Use <details> and <summary> to create expandable sections of content.',
    code: '<details><summary>Click to reveal more information</summary><p>Additional content goes here.</p></details>'
  },
  {
    title: 'Wrapping Content in <mark>',
    description: 'Use <mark> to highlight or mark text within its context.',
    code: '<p>This is an example of <mark>highlighted text</mark>.</p>'
  },
  {
    title: 'Creating Abbreviations with <abbr>',
    description: 'Use <abbr> to define abbreviations and acronyms, optionally providing the full text as a title attribute.',
    code: '<p><abbr title="HyperText Markup Language">HTML</abbr> is the backbone of the web.</p>'
  },
  {
    title: 'Representing Keyboard Input with <kbd>',
    description: 'Use <kbd> to represent user keyboard input.',
    code: '<p>Press <kbd>Ctrl</kbd> + <kbd>C</kbd> to copy the selected text.</p>'
  },
  {
    title: 'Defining Variable Content with <var>',
    description: 'Use <var> to indicate variables or placeholders.',
    code: '<p>The formula for the area of a circle is <var>πr<sup>2</sup></var>.</p>'
  },
  {
    title: 'Adding Horizontal Rules with <hr>',
    description: 'The <hr> element inserts a thematic break or horizontal rule.',
    code: '<p>This is some content.<hr>This is more content.</p>'
  },
  {
    title: 'Creating Contact Information with <address>',
    description: 'Use <address> to indicate contact information for the nearest article or body of content.',
    code: '<address><p>Contact us at <a href="mailto:contact@example.com">contact@example.com</a></p></address>'
  },
  {
    title: 'Using <article> for Standalone Content',
    description: 'The <article> element is used to wrap standalone content that can be distributed independently, such as blog posts or news articles.',
    code: '<article><h2>Article Title</h2><p>Article content goes here.</p></article>'
  },
  {
    title: 'Displaying Figures with <figure> and <figcaption>',
    description: 'Use <figure> to contain media, such as images, and <figcaption> to provide a caption for the media.',
    code: '<figure><img src="image.jpg" alt="Image description"><figcaption>Image caption goes here.</figcaption></figure>'
  },
  {
    title: 'Creating a Drop-Down List with <select>',
    description: 'Use <select> to create a drop-down list or a combo box for users to select options from.',
    code: '<select name="dropdown"><option value="option1">Option 1</option><option value="option2">Option 2</option></select>'
  },
  {
    title: 'Grouping Form Elements with <fieldset>',
    description: 'Use <fieldset> to group related form elements together and provide a legend for clarification.',
    code: '<form><fieldset><legend>Contact Information</legend><input type="text" name="name"><input type="email" name="email"></fieldset></form>'
  },
  {
    title: 'Defining Form Labels with <label>',
    description: 'Use <label> to associate form elements with their labels for better accessibility and user experience.',
    code: '<form><label for="name">Name:</label><input type="text" id="name" name="name"></form>'
  },
  {
    title: 'Using <time> for Dates and Times',
    description: 'Use <time> to define a specific date, time, or duration within your content.',
    code: '<p>My birthday is on <time datetime="2023-07-15">July 15th, 2023</time>.</p>'
  },
  {
    title: 'Representing Tables with <table>',
    description: 'Use <table> to create structured tables on your webpage.',
    code: '<table><tr><th>Name</th><th>Age</th></tr><tr><td>John</td><td>30</td></tr></table>'
  },
  {
    title: 'Creating Table Headers with <thead>',
    description: 'Use <thead> to group table header content inside a <table> element.',
    code: '<table><thead><tr><th>Name</th><th>Age</th></tr></thead><tbody><tr><td>John</td><td>30</td></tr></tbody></table>'
  }
]

export {htmlTagTips}