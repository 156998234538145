import React, { useEffect, useRef, useState } from 'react'

export default function Test() {
  const [msgText, setMsgText] = useState('')
  const [messages, setMessages] = useState(msgs)
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  const sent = (sent) => {
    if (msgText.trim('').length === 0) return;
    setMessages(val => ([...val, { id: Math.random().toString(), text: msgText, type: sent ? 'send' : 'receive' }]));
    setMsgText('');
    inputRef.current.focus();
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the default behavior of Enter key (e.g., adding a new line)
      sent(true); // Send the message
    }
  };

  // const tabHeight = window.outerHeight - window.innerHeight

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh)' }}>
      <div style={{ flex: 1, overflowY: 'scroll' }}>
        {messages.map(message => (
          <div key={message.id} style={{ display: 'flex', justifyContent: message.type === 'send' ? 'flex-end' : 'flex-start', marginTop: 5, marginInline: 10, }}>
            <span style={{ backgroundColor: '#eee', borderRadius: '10px', padding: 5, paddingInline: 15, }}>{message.text}</span>
          </div>
        ))}
        <div ref={messagesEndRef} style={{ marginTop: 5 }} />
      </div>
      <div style={{ padding: 10, backgroundColor: 'grey', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
        <button onClick={() => sent(false)}>receive</button>
        <textarea ref={inputRef} value={msgText} onChange={e => setMsgText(e.target.value)} style={{ resize: 'none' }} onKeyDown={handleKeyDown}></textarea>
        <button onClick={() => sent(true)}>send</button>
      </div>
    </div>
  )
}


const msgs = [
  {
    "id": "0.8817335929903238",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.5598717034237697",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.777344031688874",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.17160187360238388",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.733604480745599",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.11606059804731128",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.6667673300374337",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.6288393162348889",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.9530397330225884",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.5470360077127994",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.3580974787876252",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.4220686608396056",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.4046430647967092",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.3606916649775438",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.547164927538887",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.42801382504717855",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.9135319177740475",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.595623131977618",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.8744596492164078",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.21132250438337996",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.8492527212500041",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.7502068812933753",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.9506244830958086",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.3689425751424156",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.18169363954789897",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.7720729438609522",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.7989323872247425",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.4947965147148068",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.7256482001193663",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.27205194778307806",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.8292815905379596",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.4417588098056575",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.8308374450128972",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.42903142988428544",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.7867930809416173",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.8050131536603693",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.16812950352677225",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.3356668766125912",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.864494790581906",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.2568574152377807",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.4938874624113816",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.012432117618966343",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.34741467409125315",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.47177184776119296",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.4920937278112143",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.6430958896716179",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.06622073990659749",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.38575227297284975",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.9429388432274162",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.4943061733284304",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.7320009130942136",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.9041625655897407",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.27730633639008384",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.5663539287664225",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.9553870252588099",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.27103645060269455",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.1537354323712996",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.6329372157923823",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.6124618494230922",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.5865451295678952",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.884803321885604",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.1679144361215137",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.45724657849448236",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.22329243028540446",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.18783435499348533",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.5056398352666958",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.412688987866046",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.009447754815806952",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.3077954074833187",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.12415342070021862",
    "text": "This is a test message",
    "type": "receive"
  },
  {
    "id": "0.5843148186587146",
    "text": "This is a test message",
    "type": "send"
  },
  {
    "id": "0.39405674730185436",
    "text": "This is a test message",
    "type": "receive"
  }
]