const cssTips = [
  {
    title: "Creating a Responsive Layout",
    description:
      "Demonstrate your ability to design and implement responsive web layouts that adapt to different screen sizes and devices.",
    code: `
/* Example CSS for Responsive Layout */
.container {
  display: flex;
  flex-wrap: wrap;
}

.item {
  flex: 1 0 300px;
  margin: 10px;
}

@media (max-width: 768px) {
  .item {
    flex-basis: 100%;
  }
}
    `,
  },
  {
    title: "Customizing Form Styles",
    description:
      "Showcase your skills in customizing form elements like input fields, checkboxes, and radio buttons to enhance the overall user experience.",
    code: `
/* Example CSS for Customized Form Styles */
input[type="text"], textarea {
  border: 2px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

input[type="checkbox"], input[type="radio"] {
  appearance: none;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 50%;
  vertical-align: middle;
}

input[type="checkbox"]:checked, input[type="radio"]:checked {
  background-color: #007bff;
  border-color: #007bff;
}
    `,
  },
  {
    title: "Implementing CSS Grid",
    description:
      "Show how you can use CSS Grid to create complex, flexible layouts without relying heavily on traditional HTML structures.",
    code: `
/* Example CSS for CSS Grid Layout */
.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.item {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}
    `,
  },
  // Add more CSS tips here...
  {
    title: "CSS Transitions and Animations",
    description:
      "Create smooth transitions and animations to add engaging interactions to your web projects.",
    code: `
/* Example CSS for Transition and Animation */
.box {
  width: 100px;
  height: 100px;
  background-color: #007bff;
  transition: background-color 0.3s ease-in-out;
}

.box:hover {
  background-color: #ff6347;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.animated-box {
  animation: fadeInOut 2s infinite;
}
    `,
  },
  {
    title: "Flexbox Mastery",
    description:
      "Showcase your expertise in using Flexbox to build responsive and flexible components with ease.",
    code: `
/* Example CSS for Flexbox */
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item {
  flex: 1;
  padding: 10px;
}
    `,
  },
  {
    title: "Styling with CSS Variables (Custom Properties)",
    description:
      "Use CSS variables to make your styles more maintainable and easier to change globally.",
    code: `
/* Example CSS with CSS Variables */
:root {
  --primary-color: #007bff;
  --border-radius: 5px;
}

.container {
  background-color: var(--primary-color);
  border-radius: var(--border-radius);
}

.button {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: var(--border-radius);
}
    `,
  },
  {
    title: "Creative Text Effects",
    description:
      "Exhibit your creativity by showcasing different text effects like gradients, shadows, and animations.",
    code: `
/* Example CSS for Text Effects */
.text-effect {
  background: linear-gradient(45deg, #007bff, #ff6347);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.text-animation {
  animation: textGlow 2s infinite;
}

@keyframes textGlow {
  0% { text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); }
  50% { text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8); }
  100% { text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); }
}
    `,
  },
  {
    title: "CSS Blend Modes",
    description:
      "Use blend modes creatively to combine images and backgrounds for visually appealing effects.",
    code: `
/* Example CSS for Blend Modes */
.image-container {
  background-image: url('path-to-image.jpg');
  background-color: #007bff;
  mix-blend-mode: screen;
}

.text-with-background {
  background-color: #ff6347;
  mix-blend-mode: multiply;
  color: #fff;
}
    `,
  },
  {
    title: "Creating Custom Buttons",
    description:
      "Display a variety of custom-designed buttons with hover effects to demonstrate your attention to detail and design skills.",
    code: `
/* Example CSS for Custom Buttons */
.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.button:hover {
  background-color: #0056b3;
}

.button.secondary {
  background-color: #ff6347;
}

.button.secondary:hover {
  background-color: #d13e1d;
}
    `,
  },
  {
    title: "Responsive Image Techniques",
    description:
      "Showcase different methods to handle responsive images, such as using srcset and sizes attributes, art direction, and lazy loading.",
    code: `
/* Example CSS for Responsive Images */
.img-responsive {
  max-width: 100%;
  height: auto;
}
    `,
  },
  {
    title: "Creating a Sliding Navigation Menu",
    description:
      "Implement a sliding navigation menu that opens and closes smoothly to improve the user experience.",
    code: `
/* Example CSS for Sliding Navigation Menu */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1000;
}

.menu-icon {
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  background-color: #333;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.menu.open {
  transform: translateX(0);
}

.menu ul {
  list-style: none;
  padding: 20px;
}

.menu li {
  margin-bottom: 10px;
}

.menu a {
  color: #fff;
  text-decoration: none;
}
    `,
  },
  {
    title: "CSS Flexbox Carousel",
    description:
      "Create a carousel using CSS Flexbox to showcase images or content in an interactive slider.",
    code: `
/* Example CSS for Flexbox Carousel */
.carousel-container {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.carousel-slide {
  flex: 1 0 100%;
  min-width: 100%;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide img {
  width: 100%;
  height: auto;
}

.carousel-button {
  cursor: pointer;
}

.carousel-button.prev {
  margin-right: 10px;
}

.carousel-button.next {
  margin-left: 10px;
}
    `,
  },
  {
    title: "Creating a Gradient Background",
    description:
      "Design a smooth gradient background to enhance the visual appeal of your web projects.",
    code: `
/* Example CSS for Gradient Background */
body {
  background: linear-gradient(135deg, #007bff, #ff6347);
}
    `,
  },
  {
    title: "Sticky Header on Scroll",
    description:
      "Make a header stick to the top of the page as the user scrolls down for easy navigation.",
    code: `
/* Example CSS for Sticky Header */
header {
  position: sticky;
  top: 0;
  background-color: #333;
  padding: 10px 0;
  z-index: 1000;
}
    `,
  },
  {
    title: "CSS 3D Animation",
    description:
      "Create impressive 3D animations using CSS transforms and transitions.",
    code: `
/* Example CSS for 3D Animation */
.box {
  width: 100px;
  height: 100px;
  background-color: #007bff;
  transform-style: preserve-3d;
  transition: transform 0.5s ease-in-out;
}

.box:hover {
  transform: rotateX(360deg) rotateY(360deg);
}
    `,
  },
  {
    title: "Using CSS Gradients for Text",
    description:
      "Apply gradients to text elements for a captivating visual effect.",
    code: `
/* Example CSS for Gradient Text */
.gradient-text {
  background: linear-gradient(135deg, #007bff, #ff6347);
  -webkit-background-clip: text;
  color: transparent;
}
    `,
  },
  {
    title: "Creating a Split-Screen Layout",
    description:
      "Build a split-screen layout to showcase different content side by side.",
    code: `
/* Example CSS for Split-Screen Layout */
.container {
  display: flex;
}

.left-panel, .right-panel {
  flex: 1;
  padding: 20px;
}

.left-panel {
  background-color: #007bff;
  color: #fff;
}

.right-panel {
  background-color: #ff6347;
  color: #fff;
}
    `,
  },
  {
    title: "CSS Tooltip",
    description:
      "Design custom tooltips to provide additional information when users hover over elements.",
    code: `
/* Example CSS for Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltip-text {
  visibility: hidden;
  opacity: 0;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s ease-in-out;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
    `,
  },
  {
    title: "CSS Loading Animation",
    description:
      "Create loading animations to keep users engaged while content is being fetched or processed.",
    code: `
/* Example CSS for Loading Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 3px solid #333;
  border-radius: 50%;
  border-top: 3px solid #007bff;
  animation: spin 1s linear infinite;
}
    `,
  },
  {
    title: "Creating an Accordion",
    description:
      "Build an accordion-style content display with collapsible sections to save space and improve user experience.",
    code: `
/* Example CSS for Accordion */
.accordion {
  display: flex;
  flex-direction: column;
}

.accordion-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.accordion-header {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.accordion-content {
  padding: 10px;
  display: none;
}

.accordion-item.active .accordion-content {
  display: block;
}
    `,
  },
  {
    title: "CSS Pagination Styles",
    description:
      "Design visually appealing pagination styles for long lists or multiple pages of content.",
    code: `
/* Example CSS for Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-item {
  margin: 0 5px;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #007bff;
  border-radius: 5px;
}

.page-item.active {
  background-color: #007bff;
  color: #fff;
}
    `,
  },
  {
    title: "CSS Card Design",
    description:
      "Create attractive card designs for displaying various types of content in a consistent manner.",
    code: `
/* Example CSS for Card Design */
.card {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.card-image {
  max-width: 100%;
  border-radius: 5px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-content {
  color: #666;
  line-height: 1.6;
}
    `,
  },
  {
    title: "CSS Modal (Popup) Design",
    description:
      "Design a visually appealing modal (popup) to display additional content or messages on top of the main page.",
    code: `
/* Example CSS for Modal (Popup) */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
    `,
  },
  {
    title: "CSS Image Gallery",
    description:
      "Create an image gallery with smooth transitions and a lightbox effect for a professional look and feel.",
    code: `
/* Example CSS for Image Gallery */
.gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.gallery-item {
  position: relative;
  cursor: pointer;
}

.gallery-item img {
  width: 100%;
  height: auto;
}

.lightbox {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  justify-content: center;
  align-items: center;
}

.lightbox img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}
    `,
  },
  {
    title: "Creating CSS Breadcrumbs",
    description:
      "Design breadcrumbs to provide easy navigation and context for users within your website.",
    code: `
/* Example CSS for Breadcrumbs */
.breadcrumbs {
  display: flex;
  list-style: none;
  padding: 0;
}

.breadcrumb-item {
  margin-right: 5px;
}

.breadcrumb-item::after {
  content: '>';
  margin-left: 5px;
  color: #ccc;
}

.breadcrumb-item:last-child::after {
  content: none;
}
    `,
  },
  {
    title: "CSS Flip Card Animation",
    description:
      "Create a flip card animation to display content on both sides of the card with a smooth transition.",
    code: `
/* Example CSS for Flip Card Animation */
.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 200px;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
}

.flip-card-back {
  background-color: #ff6347;
  color: #fff;
  border-radius: 5px;
  transform: rotateY(180deg);
}
    `,
  },
  {
    title: "CSS Marquee Animation",
    description:
      "Add a classic marquee scrolling effect to text elements for a nostalgic touch.",
    code: `
/* Example CSS for Marquee Animation */
.marquee {
  white-space: nowrap;
  overflow: hidden;
  animation: marquee 5s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
    `,
  },
];
export { cssTips };
