import React from "react";
import { Route, Routes } from "react-router-dom";
import withRouter from "../hooks/withRouter"
import { Socialicons } from "../components/socialicons";
// import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Notes } from "../pages/notes";
import { Note } from "../pages/note";
import NotFound from "../pages/notfound";
import AppContainer from "../pages/main";

const AnimatedRoutes = withRouter(({ location }) => (
  // <TransitionGroup>
  //   <CSSTransition
  //     key={location.key}
  //     timeout={{
  //       enter: 400,
  //       exit: 400,
  //     }}
  //     classNames="my-node"
  //     unmountOnExit
  //   >
  <Routes location={location}>
    <Route exact path="/notes" element={<Notes />} />
    <Route path="/" element={<AppContainer />} />
    <Route path="/notes/:noteId" element={<Note />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
  //   </CSSTransition>
  // </TransitionGroup>
));

function AppRoutes() {
  return (
    <div className="s_c">
      <AnimatedRoutes />
      <Socialicons />
    </div>
  );
}

export default AppRoutes;
