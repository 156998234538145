const jsTipsData = [
  {
    title: "Use const and let Instead of var",
    description: "In modern JavaScript, prefer using const and let to declare variables instead of var. const is used for variables that do not change their value, and let is used for variables that can be reassigned.",
    code: `
      // Instead of using var
      var myVar = 10;

      // Use const or let
      const myConst = 42;
      let myLet = 'Hello';
    `,
  },
  {
    title: "Arrow Functions for Concise Syntax",
    description: "Use arrow functions (=>) for more concise function syntax, especially for short functions or when dealing with lexical scoping of this.",
    code: `
      // Traditional function
      function add(a, b) {
        return a + b;
      }

      // Arrow function
      const add = (a, b) => a + b;
    `,
  },
  {
    title: "Use Template Literals",
    // eslint-disable-next-line no-template-curly-in-string
    description: "Template literals (backticks) make it easier to create dynamic strings by allowing placeholders (${expression}) within the string.",
    code: `
      // Without template literals
      const name = 'John';
      const greeting = 'Hello, ' + name + '!';

      // With template literals
      const name = 'John';
      const greeting = \`Hello, \${name}!\`;
    `,
  },
  {
    title: "Destructuring for Cleaner Code",
    description: "Use destructuring to extract values from objects and arrays, which can lead to cleaner and more concise code.",
    code: `
      // Destructuring objects
      const person = { name: 'Alice', age: 30 };
      const { name, age } = person;
      console.log(name); // Output: Alice

      // Destructuring arrays
      const numbers = [1, 2, 3, 4, 5];
      const [first, second, ...rest] = numbers;
      console.log(rest); // Output: [3, 4, 5]
    `,
  },
  {
    title: "Default Parameters in Functions",
    description: "Use default parameters in functions to provide fallback values when arguments are not provided or are undefined.",
    code: `
      function greet(name = 'Guest') {
        return \`Hello, \${name}!\`;
      }

      console.log(greet()); // Output: Hello, Guest!
      console.log(greet('John')); // Output: Hello, John!
    `,
  },
  {
    title: "Spread Operator for Arrays and Objects",
    description: "The spread operator (...variable) allows you to create shallow copies of arrays and objects or combine them into new arrays and objects.",
    code: `
      // Copy arrays
      const originalArray = [1, 2, 3];
      const copiedArray = [...originalArray];

      // Merge arrays
      const array1 = [1, 2];
      const array2 = [3, 4];
      const mergedArray = [...array1, ...array2];

      // Copy objects
      const originalObject = { name: 'Alice', age: 30 };
      const copiedObject = { ...originalObject };

      // Merge objects
      const object1 = { name: 'Alice' };
      const object2 = { age: 30 };
      const mergedObject = { ...object1, ...object2 };
    `,
  },
  {
    title: "Use Object Shorthand",
    description: "When defining object properties with variable names, you can use object shorthand syntax to shorten the code.",
    code: `
      const name = 'John';
      const age = 25;

      // Without object shorthand
      const person = { name: name, age: age };

      // With object shorthand
      const person = { name, age };
    `,
  },
  {
    title: "Array Methods: map, filter, reduce",
    description: "Learn and leverage array methods like map, filter, and reduce for more concise and functional programming-style code.",
    code: `
      const numbers = [1, 2, 3, 4, 5];

      // Use map to create a new array with modified values
      const doubled = numbers.map((num) => num * 2); // Output: [2, 4, 6, 8, 10]

      // Use filter to create a new array with filtered values
      const evenNumbers = numbers.filter((num) => num % 2 === 0); // Output: [2, 4]

      // Use reduce to compute a single value from an array
      const sum = numbers.reduce((acc, num) => acc + num, 0); // Output: 15
    `,
  },
  {
    title: "Async/Await for Asynchronous Operations",
    description: "Async/await provides a more synchronous way to write asynchronous code, making it easier to read and maintain.",
    code: `
      // Without async/await
      function fetchData() {
        return fetch('https://api.example.com/data')
          .then((response) => response.json())
          .then((data) => console.log(data))
          .catch((error) => console.error(error));
      }

      // With async/await
      async function fetchData() {
        try {
          const response = await fetch('https://api.example.com/data');
          const data = await response.json();
          console.log(data);
        } catch (error) {
          console.error(error);
        }
      }
    `,
  },
  {
    title: "Modules for Code Organization",
    description: "Use ES modules (import/export) to organize and modularize your JavaScript code. Break down large files into smaller modules for better maintainability.",
    code: `
      // In module.js
      export function add(a, b) {
        return a + b;
      }

      export function subtract(a, b) {
        return a - b;
      }

      // In main.js
      import { add, subtract } from './module.js';

      console.log(add(5, 3)); // Output: 8
      console.log(subtract(10, 3)); // Output: 7
    `,
  },
  {
    title: "Avoid Using eval()",
    description: "Avoid using the eval() function, as it can execute arbitrary code and pose security risks. Instead, use safer alternatives like JSON.parse() or Function() constructor.",
    code: `
      // Avoid using eval()
      const num = 42;
      const result = eval('num + 2'); // This is unsafe and should be avoided

      // Use alternatives
      const num = 42;
      const result = num + 2; // This is safer and recommended
    `,
  },
  {
    title: "Avoid Modifying Objects You Don't Own",
    description: "Avoid directly modifying objects you don't own, especially native objects like Array.prototype or Object.prototype. It can lead to unexpected behavior and compatibility issues.",
    code: `
      // Avoid modifying native prototypes
      Array.prototype.customMethod = function () {
        // Custom method added to Array.prototype
      };

      // Use safer approaches
      const customMethod = (arr) => {
        // Your custom method for arrays
      };

      // Instead of modifying the native prototype
      const myArray = [1, 2, 3];
      customMethod(myArray);

      // This way, you avoid potential issues with modifying the prototype
    `,
  },
  {
    title: "Use Object.freeze() for Immutable Objects",
    description: "To prevent accidental modifications, you can use Object.freeze() to make objects and their properties immutable. Once an object is frozen, you cannot add, delete, or modify its properties.",
    code: `
      const user = {
        name: 'John',
        age: 30,
      };

      const immutableUser = Object.freeze(user);

      // Now, any attempts to modify immutableUser will throw an error
      immutableUser.age = 31; // Throws an error in strict mode

      console.log(immutableUser.age); // Output: 30
    `,
  },
  {
    title: "Use Strict Mode",
    description: "Enable strict mode in your JavaScript code to catch common coding mistakes, prevent accidental global variable creations, and enforce a safer and more optimized code execution.",
    code: `
      // Without strict mode
      function doSomething() {
        x = 10; // Accidentally creating a global variable
      }

      // With strict mode
      function doSomething() {
        'use strict';
        x = 10; // Throws a ReferenceError, x is not defined
      }
    `,
  },
  {
    title: "Event Delegation for Efficient Event Handling",
    description: "Use event delegation to attach event listeners to a parent element instead of individual child elements. This can lead to better performance, especially when dealing with a large number of elements.",
    code: `
      // Without event delegation
      const buttons = document.querySelectorAll('.btn');
      buttons.forEach((button) => {
        button.addEventListener('click', () => {
          // Handle button click
        });
      });

      // With event delegation
      const parent = document.getElementById('parent-container');
      parent.addEventListener('click', (event) => {
        if (event.target.matches('.btn')) {
          // Handle button click
        }
      });
    `,
  },
  {
    title: "Avoid Callback Hell with Async/Await or Promises",
    description: "To avoid callback hell (nested and hard-to-read callbacks), use async/await or Promises for more organized and linear asynchronous code.",
    code: `
      // Without async/await or Promises
      asyncFunction1((data1) => {
        asyncFunction2(data1, (data2) => {
          asyncFunction3(data2, (data3) => {
            // Handle data3
          });
        });
      });

      // With async/await
      async function myAsyncFunction() {
        const data1 = await asyncFunction1();
        const data2 = await asyncFunction2(data1);
        const data3 = await asyncFunction3(data2);
        // Handle data3
      }

      // With Promises
      asyncFunction1()
        .then((data1) => asyncFunction2(data1))
        .then((data2) => asyncFunction3(data2))
        .then((data3) => {
          // Handle data3
        });
    `,
  },
  {
    title: "Use the Fetch API for HTTP Requests",
    description: "Instead of using XMLHttpRequest, use the modern Fetch API for making HTTP requests. Fetch is simpler, more intuitive, and returns Promises for better asynchronous handling.",
    code: `
      // Using Fetch API for GET request
      fetch('https://api.example.com/data')
        .then((response) => response.json())
        .then((data) => {
          // Handle the data
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    `,
  },
  {
    title: "Use ES Modules (import/export) over CommonJS (require)",
    description: "In modern JavaScript, prefer using ES modules (import/export) instead of CommonJS (require) for better compatibility with browsers and to benefit from static analysis and tree-shaking.",
    code: `
      // Using ES modules
      // module.js
      export function myFunction() {
        // Your code here
      }

      // main.js
      import { myFunction } from './module.js';
      myFunction();

      // Using CommonJS
      // module.js
      module.exports = {
        myFunction: function () {
          // Your code here
        },
      };

      // main.js
      const module = require('./module.js');
      module.myFunction();
    `,
  },
  {
    title: "Use BigInt for Large Numbers",
    description: "When dealing with large numbers that exceed the safe integer range, use BigInt to ensure accurate and precise calculations.",
    code: `
      const largeNumber = 9007199254740991n;
      const anotherLargeNumber = BigInt('9007199254740991');

      // Perform calculations with BigInt
      const result = largeNumber + 2n;
      console.log(result); // Output: 9007199254740993n
    `,
  },
  {
    title: "Avoid Modifying Arguments Object",
    description: "In function arguments, avoid modifying the arguments object directly, as it can lead to unexpected behavior and may not work in strict mode.",
    code: `
      // Avoid modifying arguments object
      function sum(a, b) {
        arguments[0] = 10; // Modifying arguments directly
        return a + b; // Returns 10 + b, not the expected sum
      }

      console.log(sum(5, 3)); // Output: 13, not 8
    `,
  },
  {
    title: "Use Logical OR for Default Values",
    description: "To provide default values for variables, parameters, or properties, you can use the logical OR (||) operator instead of a full if statement.",
    code: `
      // Without logical OR
      const name = providedName || 'Guest';

      // With logical OR
      const name = providedName || 'Guest';
    `,
  },
  {
    title: "Avoid Empty Loops",
    description: "Avoid using empty loops like while(true) without a break statement or loops that serve no purpose. Empty loops can lead to infinite loops and crash your application.",
    code: `
      // Empty loop without break
      while (true) {
        // Some code...
      }

      // Empty loop that serves no purpose
      for (let i = 0; i < 10; i++) {
        // No code inside the loop
      }
    `,
  },
  {
    title: "Use === and !== Instead of == and !=",
    description: "Prefer strict equality (===) and strict inequality (!==) to avoid type coercion and ensure accurate comparisons.",
    code: `
      const num1 = 5;
      const num2 = '5';

      console.log(num1 === num2); // Output: false, as the types are different
      console.log(num1 == num2); // Output: true, but it performs type coercion
    `,
  },
  {
    title: "Avoid eval() for Parsing JSON",
    description: "Avoid using eval() to parse JSON data, as it can execute arbitrary code and pose security risks. Instead, use JSON.parse() to safely convert JSON strings to JavaScript objects.",
    code: `
      // Avoid using eval() for parsing JSON
      const jsonString = '{"name": "John", "age": 30}';
      const data = JSON.parse(jsonString);

      console.log(data.name); // Output: John
    `,
  },
  {
    title: "Use Map and Set for Unique Collections",
    description: "When dealing with collections that require unique values, use Map and Set data structures to efficiently manage data without duplicates.",
    code: `
      // Using Set for unique values
      const uniqueNumbers = new Set([1, 2, 3, 2, 1]);
      console.log([...uniqueNumbers]); // Output: [1, 2, 3]

      // Using Map for key-value pairs
      const myMap = new Map();
      myMap.set('name', 'John');
      myMap.set('age', 30);
      console.log(myMap.get('name')); // Output: John
    `,
  },
  {
    title: "Use Object.fromEntries()",
    description: "Object.fromEntries() method allows you to create an object from an array of key-value pairs. It's a concise way to build objects programmatically.",
    code: `
      // Using Object.fromEntries()
      const entries = [['name', 'John'], ['age', 30]];
      const myObject = Object.fromEntries(entries);
      console.log(myObject); // Output: { name: 'John', age: 30 }
    `,
  },
  {
    title: "Use Optional Chaining (?.)",
    description: "Optional chaining (?.) is a feature that allows you to safely access nested properties and methods without causing an error if any intermediate value is null or undefined.",
    code: `
      const user = {
        name: 'John',
        address: {
          city: 'New York',
          zipCode: 12345,
        },
      };

      console.log(user.address?.city); // Output: New York
      console.log(user.address?.street?.name); // Output: undefined, without throwing an error
    `,
  },
  {
    title: "Use Nullish Coalescing Operator (??)",
    description: "The nullish coalescing operator (??) allows you to provide a default value when dealing with null or undefined values, but it excludes other falsy values like 0 or an empty string.",
    code: `
      const username = null;
      const defaultName = 'Guest';

      console.log(username ?? defaultName); // Output: Guest

      const count = 0;
      const defaultCount = 10;

      console.log(count ?? defaultCount); // Output: 0
    `,
  },
  {
    title: "Avoid modifying DOM during Loops",
    description: "Avoid manipulating the DOM inside loops, as it can trigger expensive reflows and repaints. Instead, build a string or manipulate an array, then perform a single DOM update.",
    code: `
      // Avoid DOM manipulation inside loops
      const list = document.getElementById('my-list');
      const data = [1, 2, 3, 4, 5];

      data.forEach((item) => {
        const listItem = document.createElement('li');
        listItem.textContent = item;
        list.appendChild(listItem);
      });

      // Prefer building the HTML string outside the loop
      const list = document.getElementById('my-list');
      const data = [1, 2, 3, 4, 5];
      let htmlString = '';

      data.forEach((item) => {
        htmlString += \`<li>\${item}</li>\`;
      });

      list.innerHTML = htmlString;
    `,
  },
  {
    title: "Use Performance.now() for Performance Measurement",
    description: "For precise performance measurement, use the Performance API's Performance.now() method, which provides high-resolution timestamps.",
    code: `
      const startTime = performance.now();

      // Perform some task

      const endTime = performance.now();
      const elapsedTime = endTime - startTime;
      console.log('Task completed in ' + elapsedTime + ' milliseconds');
    `,
  },
  {
    title: "Use Web Workers for CPU-Intensive Tasks",
    description: "For CPU-intensive tasks that might block the main thread and affect the user experience, use Web Workers to run the task in the background without affecting the UI responsiveness.",
    code: `
      // In the main thread
      const worker = new Worker('worker.js');

      worker.postMessage({ data: someData });

      worker.onmessage = (event) => {
        const result = event.data;
        // Handle the result from the worker
      };

      // In worker.js
      self.onmessage = (event) => {
        const data = event.data;
        // Perform CPU-intensive task using data
        self.postMessage(result);
      };
    `,
  },
  {
    title: "Use Intersection Observer for Lazy Loading",
    description: "For lazy loading images or content as users scroll down a page, use the Intersection Observer API to efficiently detect when an element comes into view.",
    code: `
      const images = document.querySelectorAll('.lazy-image');

      const handleIntersection = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const image = entry.target;
            image.src = image.dataset.src;
            observer.unobserve(image);
          }
        });
      };

      const imageObserver = new IntersectionObserver(handleIntersection);

      images.forEach((image) => {
        imageObserver.observe(image);
      });
    `,
  },
  {
    title: "Use SetTimeout with 0ms for Deferred Execution",
    description: "To perform an action after the current event loop has finished, you can use setTimeout with a timeout value of 0ms, which defers the execution to the next tick.",
    code: `
      // Deferred execution using setTimeout with 0ms
      console.log('Start');
      setTimeout(() => {
        console.log('Deferred execution');
      }, 0);
      console.log('End');
    `,
  },
];

export {jsTipsData};